var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "topTitle paddingL20 paddingT10" },
      [
        _c(
          "el-col",
          { attrs: { span: 12 } },
          [_c("titleModule", { attrs: { title: "添加素材" } })],
          1
        ),
        _c("el-col", { attrs: { span: 12 } }, [
          _c(
            "div",
            { staticClass: "handleBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                { attrs: { size: "medium" }, on: { click: _vm.handlePreview } },
                [_vm._v("预览")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "Wrapper" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticStyle: { width: "940px" },
            attrs: {
              "label-position": "right",
              "label-width": "140px",
              model: _vm.formInline,
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "素材名称:", prop: "materialName" } },
              [
                _c("el-input", {
                  staticStyle: { width: "400px" },
                  attrs: {
                    placeholder: "请输入素材名称",
                    maxlength: "30",
                    "show-word-limit": "",
                  },
                  model: {
                    value: _vm.formInline.materialName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "materialName", $$v)
                    },
                    expression: "formInline.materialName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "素材类型:", prop: "materialType" } },
              [
                _c(
                  "el-select",
                  {
                    on: { change: _vm.handleMaterialType },
                    model: {
                      value: _vm.formInline.materialType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "materialType", $$v)
                      },
                      expression: "formInline.materialType",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "空余泊位", value: 1 } }),
                    _c("el-option", { attrs: { label: "图片", value: 2 } }),
                    _c("el-option", { attrs: { label: "视频", value: 4 } }),
                    _c("el-option", { attrs: { label: "文本", value: 8 } }),
                    _c("el-option", {
                      attrs: { label: "图片+文本", value: 10 },
                    }),
                    _c("el-option", {
                      attrs: { label: "视频+文本", value: 12 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("el-form-item", { attrs: { label: "屏幕尺寸:" } }, [
                      _c("span"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "屏幕高度", prop: "screenHeight" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formInline.screenHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "screenHeight", $$v)
                            },
                            expression: "formInline.screenHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "屏幕宽度", prop: "screenWidth" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formInline.screenWidth,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "screenWidth", $$v)
                            },
                            expression: "formInline.screenWidth",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.formInline.materialType !== 8
              ? _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.labelText + "坐标:" } },
                          [_c("span")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "X坐标", prop: "mediaX" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.formInline.mediaX,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInline, "mediaX", $$v)
                                },
                                expression: "formInline.mediaX",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Y坐标", prop: "mediaY" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.formInline.mediaY,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInline, "mediaY", $$v)
                                },
                                expression: "formInline.mediaY",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formInline.materialType !== 8
              ? _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.labelText + "显示:" } },
                          [_c("span")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "高度",
                                      prop: "mediaShowHeight",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.formInline.mediaShowHeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "mediaShowHeight",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formInline.mediaShowHeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "宽度",
                                      prop: "mediaShowWidth",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.formInline.mediaShowWidth,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "mediaShowWidth",
                                            $$v
                                          )
                                        },
                                        expression: "formInline.mediaShowWidth",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formInline.materialType !== 8
              ? _c(
                  "el-form-item",
                  { attrs: { label: "请添加" + _vm.labelText } },
                  [
                    _c(
                      "div",
                      { staticClass: "uploadPic" },
                      [
                        _vm.formInline.materialType === 1 ||
                        _vm.formInline.materialType === 2 ||
                        _vm.formInline.materialType === 10
                          ? _c(
                              "el-upload",
                              {
                                ref: "upload",
                                staticClass: "avatar-uploader2",
                                attrs: {
                                  action: "/acb/2.0/ydpMaterial/upload",
                                  headers: _vm.uploadHeader,
                                  data: _vm.uploadData,
                                  name: "image",
                                  "before-upload": _vm.beforeAvatarUpload,
                                  "auto-upload": true,
                                  "show-file-list": false,
                                  "on-success": _vm.handleAvatarSuccess,
                                },
                              },
                              [
                                _vm.mediaResourceUrl
                                  ? _c("img", {
                                      staticClass: "avatar2",
                                      attrs: { src: _vm.mediaResourceUrl },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      "每个素材仅支持1张图片，仅支持jpg格式"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.formInline.materialType === 4 ||
                        _vm.formInline.materialType === 12
                          ? _c(
                              "el-upload",
                              {
                                ref: "upload",
                                staticClass: "avatar-uploader2",
                                attrs: {
                                  action: "/acb/2.0/ydpMaterial/upload",
                                  headers: _vm.uploadHeader,
                                  data: _vm.uploadData,
                                  name: "image",
                                  "auto-upload": true,
                                  "before-upload": _vm.beforeAvatarVideoUpload,
                                  "show-file-list": false,
                                  "on-success": _vm.handleAvatarSuccess,
                                },
                              },
                              [
                                _vm.mediaResourceUrl
                                  ? _c("video", {
                                      staticClass: "avatar2",
                                      attrs: {
                                        src: _vm.mediaResourceUrl,
                                        controls: "",
                                      },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      "每个素材仅支持1个视频，仅支持mp4格式"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.formInline.materialType === 8 ||
            _vm.formInline.materialType === 10 ||
            _vm.formInline.materialType === 12
              ? _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "文本坐标:" } },
                              [_c("span")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "X坐标", prop: "textX" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.formInline.textX,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "textX", $$v)
                                    },
                                    expression: "formInline.textX",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Y坐标", prop: "textY" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.formInline.textY,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "textY", $$v)
                                    },
                                    expression: "formInline.textY",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "文本显示:" } },
                              [_c("span")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "高度",
                                          prop: "textShowHeight",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value:
                                              _vm.formInline.textShowHeight,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "textShowHeight",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formInline.textShowHeight",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "宽度",
                                          prop: "textShowWidth",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.formInline.textShowWidth,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "textShowWidth",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formInline.textShowWidth",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "materialText" },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "字体:" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formInline.textFont,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "textFont",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.textFont",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "黑体",
                                                    value: 1,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "楷体",
                                                    value: 2,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "宋体",
                                                    value: 3,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "仿宋",
                                                    value: 4,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "隶书",
                                                    value: 5,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "字号:" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formInline.textFontSize,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "textFontSize",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.textFontSize",
                                                },
                                              },
                                              _vm._l(100, function (i) {
                                                return _c("el-option", {
                                                  key: i,
                                                  attrs: { label: i, value: i },
                                                })
                                              }),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "字体颜色:" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formInline
                                                      .textFontColor,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "textFontColor",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.textFontColor",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#ff0000",
                                                  },
                                                  attrs: {
                                                    label: "红色",
                                                    value: 1,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#008000",
                                                  },
                                                  attrs: {
                                                    label: "绿色",
                                                    value: 2,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#0000ff",
                                                  },
                                                  attrs: {
                                                    label: "蓝色",
                                                    value: 3,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#ffff00",
                                                  },
                                                  attrs: {
                                                    label: "黄色",
                                                    value: 4,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#ee82ee",
                                                  },
                                                  attrs: {
                                                    label: "紫色",
                                                    value: 5,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#00ffff",
                                                  },
                                                  attrs: {
                                                    label: "青色",
                                                    value: 6,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#ffffff",
                                                  },
                                                  attrs: {
                                                    label: "白色",
                                                    value: 7,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#000000",
                                                  },
                                                  attrs: {
                                                    label: "黑色",
                                                    value: 8,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 18 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "字号风格:" } },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formInline
                                                      .textFontStyle,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "textFontStyle",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.textFontStyle",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-button",
                                                  {
                                                    attrs: { label: 1 },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.radioClick(1)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "600",
                                                        },
                                                      },
                                                      [_vm._v("B")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-radio-button",
                                                  {
                                                    attrs: { label: 2 },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.radioClick(2)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-style":
                                                            "italic",
                                                        },
                                                      },
                                                      [_vm._v("I")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-radio-button",
                                                  {
                                                    attrs: { label: 4 },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.radioClick(4)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "text-decoration":
                                                            "underline",
                                                        },
                                                      },
                                                      [_vm._v("U")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "对齐方式:" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formInline
                                                      .textAlignment,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "textAlignment",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.textAlignment",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "左对齐",
                                                    value: 0,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "居中对齐",
                                                    value: 2,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "右对齐",
                                                    value: 1,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "播放特效:" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formInline
                                                      .textPlayEffects,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "textPlayEffects",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.textPlayEffects",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "无特效",
                                                    value: 0,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "从右往左滚动",
                                                    value: 12,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "从下往上滚动",
                                                    value: 14,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "特效速度:" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formInline
                                                      .textEffectsSpeed,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "textEffectsSpeed",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.textEffectsSpeed",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "最慢",
                                                    value: 0,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "较慢",
                                                    value: 1,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "常速",
                                                    value: 2,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "较快",
                                                    value: 3,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "最快",
                                                    value: 4,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "文本内容:", prop: "textContent" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "510px" },
                          attrs: {
                            type: "textarea",
                            rows: 10,
                            maxlength: "1000",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.formInline.textContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "textContent", $$v)
                            },
                            expression: "formInline.textContent",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formInline.materialType === 1
              ? _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-form-item", { attrs: { label: "泊位内容:" } }, [
                          _c("span"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      _vm._l(
                        _vm.formInline.berthList,
                        function (domain, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "berthBox" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "X坐标",
                                            "label-width": "80px",
                                            prop:
                                              "berthList." + index + ".textX",
                                            rules: [
                                              {
                                                required: true,
                                                message: "X坐标不能为空",
                                                trigger: "blur",
                                              },
                                              {
                                                validator: _vm.validatorNum,
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: domain.textX,
                                              callback: function ($$v) {
                                                _vm.$set(domain, "textX", $$v)
                                              },
                                              expression: "domain.textX",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Y坐标",
                                            "label-width": "80px",
                                            prop:
                                              "berthList." + index + ".textY",
                                            rules: [
                                              {
                                                required: true,
                                                message: "Y坐标不能为空",
                                                trigger: "blur",
                                              },
                                              {
                                                validator: _vm.validatorNum,
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: domain.textY,
                                              callback: function ($$v) {
                                                _vm.$set(domain, "textY", $$v)
                                              },
                                              expression: "domain.textY",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "显示高度",
                                            "label-width": "80px",
                                            prop:
                                              "berthList." +
                                              index +
                                              ".textShowHeight",
                                            rules: [
                                              {
                                                required: true,
                                                message: "显示高度不能为空",
                                                trigger: "blur",
                                              },
                                              {
                                                validator: _vm.validatorNum,
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: domain.textShowHeight,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  domain,
                                                  "textShowHeight",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "domain.textShowHeight",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "显示宽度",
                                            "label-width": "80px",
                                            prop:
                                              "berthList." +
                                              index +
                                              ".textShowWidth",
                                            rules: [
                                              {
                                                required: true,
                                                message: "显示宽度不能为空",
                                                trigger: "blur",
                                              },
                                              {
                                                validator: _vm.validatorNum,
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: domain.textShowWidth,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  domain,
                                                  "textShowWidth",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "domain.textShowWidth",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "字体",
                                            "label-width": "80px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              model: {
                                                value: domain.textFont,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    domain,
                                                    "textFont",
                                                    $$v
                                                  )
                                                },
                                                expression: "domain.textFont",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "黑体",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "楷体",
                                                  value: 2,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "宋体",
                                                  value: 3,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "仿宋",
                                                  value: 4,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "隶书",
                                                  value: 5,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "字号",
                                            "label-width": "80px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              model: {
                                                value: domain.textFontSize,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    domain,
                                                    "textFontSize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "domain.textFontSize",
                                              },
                                            },
                                            _vm._l(100, function (i) {
                                              return _c("el-option", {
                                                key: i,
                                                attrs: { label: i, value: i },
                                              })
                                            }),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "URL地址:",
                                            "label-width": "90px",
                                            prop:
                                              "berthList." +
                                              index +
                                              ".berthNumUrl",
                                            rules: {
                                              required: true,
                                              message: "URL地址不能为空",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: domain.berthNumUrl,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  domain,
                                                  "berthNumUrl",
                                                  $$v
                                                )
                                              },
                                              expression: "domain.berthNumUrl",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: index > 0,
                                                expression: "index > 0",
                                              },
                                            ],
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.removeDomain(domain)
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.addDomain },
                              },
                              [_vm._v("添加泊位")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "播放时长:", prop: "playTime" } },
              [
                _c("el-input", {
                  staticStyle: { width: "100px" },
                  attrs: { maxlength: "30" },
                  model: {
                    value: _vm.formInline.playTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "playTime", $$v)
                    },
                    expression: "formInline.playTime",
                  },
                }),
                _vm._v(" 秒 "),
                _c("span", { staticStyle: { color: "rgba(0, 0, 0, 0.6)" } }, [
                  _vm._v("(该素材播放时长)"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm.previewVisible
      ? _c(
          "div",
          {
            staticClass: "previewBox",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.previewVisible = false
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "preview",
                style: {
                  width: _vm.formInline.screenWidth + "px",
                  height: _vm.formInline.screenHeight + "px",
                },
              },
              [
                _vm.formInline.materialType === 1 ||
                _vm.formInline.materialType === 2 ||
                _vm.formInline.materialType === 10
                  ? _c(
                      "div",
                      {
                        staticClass: "imgPreview",
                        style: {
                          left: _vm.formInline.mediaX + "px",
                          top: _vm.formInline.mediaY + "px",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.mediaResourceUrl,
                            width: _vm.formInline.mediaShowWidth,
                            height: _vm.formInline.mediaShowHeight,
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm.formInline.materialType === 4 ||
                _vm.formInline.materialType === 12
                  ? _c(
                      "div",
                      {
                        staticClass: "imgPreview",
                        style: {
                          left: _vm.formInline.mediaX + "px",
                          top: _vm.formInline.mediaY + "px",
                        },
                      },
                      [
                        _c("video", {
                          attrs: {
                            src: _vm.mediaResourceUrl,
                            width: _vm.formInline.mediaShowWidth,
                            height: _vm.formInline.mediaShowHeight,
                            controls: "",
                            autoplay: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "text",
                    style: {
                      left: _vm.formInline.textX + "px",
                      top: _vm.formInline.textY + "px",
                      width: _vm.formInline.textShowWidth + "px",
                      height: _vm.formInline.textShowHeight + "px",
                      fontFamily: _vm.textFont,
                      fontSize: _vm.formInline.textFontSize + "px",
                      color: _vm.styleFontColor,
                      textAlign: _vm.styleTextAlign,
                      fontWeight:
                        _vm.formInline.textFontStyle === 1 ? 600 : 400,
                      fontStyle:
                        _vm.formInline.textFontStyle === 2
                          ? "italic"
                          : "normal",
                      textDecoration:
                        _vm.formInline.textFontStyle === 4
                          ? "underline"
                          : "normal",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.formInline.textContent) + " ")]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }