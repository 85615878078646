<!--
 * @Author: your name
 * @Date: 2021-02-24 16:43:11
 * @LastEditTime: 2021-03-12 15:15:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
-->
<template>
  <div>
    <div class="topTitle paddingL20 paddingT10">
      <el-col :span="12">
        <titleModule title="添加素材"></titleModule>
      </el-col>
      <el-col :span="12">
        <div class="handleBtn">
          <el-button size="medium" @click="$router.go(-1)">返回</el-button>
          <el-button size="medium" type="success" @click="submitForm('ruleForm')">{{ $t('button.preservation') }}</el-button>
          <el-button size="medium" @click="handlePreview">预览</el-button>
        </div>
      </el-col>
    </div>
    <div class="Wrapper">
      <el-form
        label-position="right"
        label-width="140px"
        :model="formInline"
        style="width: 940px"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="素材名称:" prop="materialName">
          <el-input
            v-model="formInline.materialName"
            style="width: 400px"
            placeholder="请输入素材名称"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="素材类型:" prop="materialType">
          <el-select v-model="formInline.materialType" @change="handleMaterialType">
            <el-option label="空余泊位" :value="1"></el-option>
            <el-option label="图片" :value="2"></el-option>
            <el-option label="视频" :value="4"></el-option>
            <el-option label="文本" :value="8"></el-option>
            <el-option label="图片+文本" :value="10"></el-option>
            <el-option label="视频+文本" :value="12"></el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="屏幕尺寸:">
              <span></span>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="屏幕高度" prop="screenHeight">
              <el-input v-model="formInline.screenHeight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="屏幕宽度" prop="screenWidth">
              <el-input v-model="formInline.screenWidth"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 图片、视频坐标 -->
        <el-row :gutter="20" v-if="formInline.materialType !== 8">
          <el-col :span="4">
            <el-form-item :label="labelText + '坐标:'">
              <span></span>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="X坐标" prop="mediaX">
              <el-input v-model="formInline.mediaX"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="Y坐标" prop="mediaY">
              <el-input v-model="formInline.mediaY"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 图片、视频显示大小 -->
        <el-row :gutter="20" v-if="formInline.materialType !== 8">
          <el-col :span="4">
            <el-form-item :label="labelText + '显示:'">
              <span></span>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="高度" prop="mediaShowHeight">
                  <el-input v-model="formInline.mediaShowHeight"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="宽度" prop="mediaShowWidth">
                  <el-input v-model="formInline.mediaShowWidth"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- 添加图片、视频 -->
        <el-form-item :label="'请添加' + labelText" v-if="formInline.materialType !== 8">
          <div class="uploadPic">
            <el-upload
              v-if="
                formInline.materialType === 1 ||
                formInline.materialType === 2 ||
                formInline.materialType === 10
              "
              ref="upload"
              class="avatar-uploader2"
              action="/acb/2.0/ydpMaterial/upload"
              :headers="uploadHeader"
              :data="uploadData"
              name="image"
              :before-upload="beforeAvatarUpload"
              :auto-upload="true"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="mediaResourceUrl" :src="mediaResourceUrl" class="avatar2" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">每个素材仅支持1张图片，仅支持jpg格式</div>
            </el-upload>
            <el-upload
              v-if="formInline.materialType === 4 || formInline.materialType === 12"
              ref="upload"
              class="avatar-uploader2"
              action="/acb/2.0/ydpMaterial/upload"
              :headers="uploadHeader"
              :data="uploadData"
              name="image"
              :auto-upload="true"
              :before-upload="beforeAvatarVideoUpload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <video v-if="mediaResourceUrl" :src="mediaResourceUrl" class="avatar2" controls />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">每个素材仅支持1个视频，仅支持mp4格式</div>
            </el-upload>
          </div>
        </el-form-item>
        <!-- 文本坐标、大小 -->
        <div
          v-if="
            formInline.materialType === 8 ||
            formInline.materialType === 10 ||
            formInline.materialType === 12
          "
        >
          <el-row :gutter="20">
            <el-col :span="4">
              <el-form-item label="文本坐标:">
                <span></span>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="X坐标" prop="textX">
                <el-input v-model="formInline.textX"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="Y坐标" prop="textY">
                <el-input v-model="formInline.textY"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4">
              <el-form-item label="文本显示:">
                <span></span>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="高度" prop="textShowHeight">
                    <el-input v-model="formInline.textShowHeight"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="宽度" prop="textShowWidth">
                    <el-input v-model="formInline.textShowWidth"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 文本 -->
              <div class="materialText">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="字体:">
                      <el-select v-model="formInline.textFont">
                        <el-option label="黑体" :value="1"></el-option>
                        <el-option label="楷体" :value="2"></el-option>
                        <el-option label="宋体" :value="3"></el-option>
                        <el-option label="仿宋" :value="4"></el-option>
                        <el-option label="隶书" :value="5"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="字号:">
                      <el-select v-model="formInline.textFontSize">
                        <el-option :label="i" :value="i" :key="i" v-for="i in 100"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="字体颜色:">
                      <el-select v-model="formInline.textFontColor">
                        <el-option
                          label="红色"
                          :value="1"
                          style="background-color: #ff0000"
                        ></el-option>
                        <el-option
                          label="绿色"
                          :value="2"
                          style="background-color: #008000"
                        ></el-option>
                        <el-option
                          label="蓝色"
                          :value="3"
                          style="background-color: #0000ff"
                        ></el-option>
                        <el-option
                          label="黄色"
                          :value="4"
                          style="background-color: #ffff00"
                        ></el-option>
                        <el-option
                          label="紫色"
                          :value="5"
                          style="background-color: #ee82ee"
                        ></el-option>
                        <el-option
                          label="青色"
                          :value="6"
                          style="background-color: #00ffff"
                        ></el-option>
                        <el-option
                          label="白色"
                          :value="7"
                          style="background-color: #ffffff"
                        ></el-option>
                        <el-option
                          label="黑色"
                          :value="8"
                          style="background-color: #000000"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="18">
                    <el-form-item label="字号风格:">
                      <el-radio-group v-model="formInline.textFontStyle">
                        <el-radio-button @click.native="radioClick(1)" :label="1"
                          ><span style="font-weight: 600">B</span></el-radio-button
                        >
                        <el-radio-button @click.native="radioClick(2)" :label="2"
                          ><span style="font-style: italic">I</span></el-radio-button
                        >
                        <el-radio-button @click.native="radioClick(4)" :label="4"
                          ><span style="text-decoration: underline">U</span></el-radio-button
                        >
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="对齐方式:">
                      <el-select v-model="formInline.textAlignment">
                        <el-option label="左对齐" :value="0"></el-option>
                        <el-option label="居中对齐" :value="2"></el-option>
                        <el-option label="右对齐" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="播放特效:">
                      <el-select v-model="formInline.textPlayEffects">
                        <el-option label="无特效" :value="0"></el-option>
                        <el-option label="从右往左滚动" :value="12"></el-option>
                        <el-option label="从下往上滚动" :value="14"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="特效速度:">
                      <el-select v-model="formInline.textEffectsSpeed">
                        <el-option label="最慢" :value="0"></el-option>
                        <el-option label="较慢" :value="1"></el-option>
                        <el-option label="常速" :value="2"></el-option>
                        <el-option label="较快" :value="3"></el-option>
                        <el-option label="最快" :value="4"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <el-form-item label="文本内容:" prop="textContent">
            <el-input
              type="textarea"
              :rows="10"
              v-model="formInline.textContent"
              style="width: 510px"
              maxlength="1000"
              show-word-limit
            ></el-input>
          </el-form-item>
        </div>
        <!-- 泊位 -->
        <el-row :gutter="20" v-if="formInline.materialType === 1">
          <el-col :span="4">
            <el-form-item label="泊位内容:">
              <span></span>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <div class="berthBox" v-for="(domain, index) in formInline.berthList" :key="index">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item
                    label="X坐标"
                    label-width="80px"
                    :prop="'berthList.' + index + '.textX'"
                    :rules="[
                      { required: true, message: 'X坐标不能为空', trigger: 'blur' },
                      {
                        validator: validatorNum,
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input v-model="domain.textX"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="Y坐标"
                    label-width="80px"
                    :prop="'berthList.' + index + '.textY'"
                    :rules="[
                      {
                        required: true,
                        message: 'Y坐标不能为空',
                        trigger: 'blur',
                      },
                      {
                        validator: validatorNum,
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input v-model="domain.textY"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="显示高度"
                    label-width="80px"
                    :prop="'berthList.' + index + '.textShowHeight'"
                    :rules="[
                      {
                        required: true,
                        message: '显示高度不能为空',
                        trigger: 'blur',
                      },
                      {
                        validator: validatorNum,
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input v-model="domain.textShowHeight"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="显示宽度"
                    label-width="80px"
                    :prop="'berthList.' + index + '.textShowWidth'"
                    :rules="[
                      {
                        required: true,
                        message: '显示宽度不能为空',
                        trigger: 'blur',
                      },
                      {
                        validator: validatorNum,
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input v-model="domain.textShowWidth"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="字体" label-width="80px">
                    <el-select v-model="domain.textFont">
                      <el-option label="黑体" :value="1"></el-option>
                      <el-option label="楷体" :value="2"></el-option>
                      <el-option label="宋体" :value="3"></el-option>
                      <el-option label="仿宋" :value="4"></el-option>
                      <el-option label="隶书" :value="5"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="字号" label-width="80px">
                    <el-select v-model="domain.textFontSize">
                      <el-option :label="i" :value="i" :key="i" v-for="i in 100"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="URL地址:"
                    label-width="90px"
                    :prop="'berthList.' + index + '.berthNumUrl'"
                    :rules="{ required: true, message: 'URL地址不能为空', trigger: 'blur' }"
                  >
                    <el-input v-model="domain.berthNumUrl"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <div style="text-align: right">
                    <el-button
                      type="danger"
                      v-show="index > 0"
                      @click.prevent="removeDomain(domain)"
                      >删除</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-button type="primary" @click="addDomain">添加泊位</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="播放时长:" prop="playTime">
          <el-input v-model="formInline.playTime" style="width: 100px" maxlength="30"></el-input> 秒
          <span style="color: rgba(0, 0, 0, 0.6)">(该素材播放时长)</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="previewBox" @click.stop="previewVisible = false" v-if="previewVisible">
      <div
        class="preview"
        :style="{ width: formInline.screenWidth + 'px', height: formInline.screenHeight + 'px' }"
      >
        <div
          class="imgPreview"
          v-if="
            formInline.materialType === 1 ||
            formInline.materialType === 2 ||
            formInline.materialType === 10
          "
          :style="{ left: formInline.mediaX + 'px', top: formInline.mediaY + 'px' }"
        >
          <img
            :src="mediaResourceUrl"
            :width="formInline.mediaShowWidth"
            :height="formInline.mediaShowHeight"
            alt=""
          />
        </div>
        <div
          class="imgPreview"
          v-if="formInline.materialType === 4 || formInline.materialType === 12"
          :style="{ left: formInline.mediaX + 'px', top: formInline.mediaY + 'px' }"
        >
          <video
            :src="mediaResourceUrl"
            :width="formInline.mediaShowWidth"
            :height="formInline.mediaShowHeight"
            controls
            autoplay
          ></video>
        </div>
        <div
          class="text"
          :style="{
            left: formInline.textX + 'px',
            top: formInline.textY + 'px',
            width: formInline.textShowWidth + 'px',
            height: formInline.textShowHeight + 'px',
            fontFamily: textFont,
            fontSize: formInline.textFontSize + 'px',
            color: styleFontColor,
            textAlign: styleTextAlign,
            fontWeight: formInline.textFontStyle === 1 ? 600 : 400,
            fontStyle: formInline.textFontStyle === 2 ? 'italic' : 'normal',
            textDecoration: formInline.textFontStyle === 4 ? 'underline' : 'normal',
          }"
        >
          {{ formInline.textContent }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleModule from "@/components/titleModule/titleModule";
import qs from "qs";
const regN = /^[0-9]*$/;
export default {
  name: "addMaterial",
  data() {
    let that = this;
    return {
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {
        materialId: "",
      },
      previewVisible: false,
      formInline: {
        materialName: "",
        materialType: 1,
        screenWidth: 0,
        screenHeight: 0,
        mediaX: 0,
        mediaY: 0,
        mediaShowHeight: 0,
        mediaShowWidth: 0,
        textX: 0,
        textY: 0,
        textShowWidth: 0,
        textShowHeight: 0,
        textFont: 1,
        textFontSize: 16,
        textFontColor: 1,
        textFontStyle: 0,
        textAlignment: 0,
        textPlayEffects: 0,
        textEffectsSpeed: 2,
        textContent: "",
        berthList: [
          {
            textX: 0,
            textY: 0,
            textShowHeight: 0,
            textShowWidth: 0,
            textFont: 1,
            textFontSize: 16,
            berthNumUrl: "",
          },
        ],
        playTime: "10",
      },
      mediaResourceUrl: "",
      rules: {
        materialName: [{ required: true, message: "请输入素材名称", trigger: "blur" }],
        screenWidth: [
          { required: true, message: "请输入屏幕宽度", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!regN.test(value)) {
                callback(new Error("只能输入数字"));
              } else if (value == 0) {
                callback(new Error("屏幕宽度不能为0"));
              } else if (value > 2147483647) {
                callback(new Error("屏幕宽度不能超过最大值"));
              }  else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        screenHeight: [
          { required: true, message: "请输入屏幕高度", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!regN.test(value)) {
                callback(new Error("只能输入数字"));
              } else if (value == 0) {
                callback(new Error("屏幕高度不能为0"));
              } else if (value > 2147483647) {
                callback(new Error("屏幕高度不能超过最大值"));
              } else  {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        mediaX: [
          { required: true, message: "请输入X坐标", trigger: "blur" },
          {
            validator: that.validatorNum,
            trigger: "blur",
          },
        ],
        mediaY: [
          { required: true, message: "请输入Y坐标", trigger: "blur" },
          {
            validator: that.validatorNum,
            trigger: "blur",
          },
        ],
        mediaShowWidth: [
          { required: true, message: "请输入宽度", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value == 0) {
                callback(new Error("宽度不能为0"));
              } else if (!regN.test(value)) {
                callback(new Error("只能输入数字"));
              } else if (value > 2147483647) {
                callback(new Error("宽度不能超过最大值"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        mediaShowHeight: [
          { required: true, message: "请输入高度", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value == 0) {
                callback(new Error("高度不能为0"));
              } else if (!regN.test(value)) {
                callback(new Error("只能输入数字"));
              } else if (value > 2147483647) {
                callback(new Error("高度不能超过最大值"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        textX: [
          { required: true, message: "请输入X坐标", trigger: "blur" },
          {
            validator: that.validatorNum,
            trigger: "blur",
          },
        ],
        textY: [
          { required: true, message: "请输入Y坐标", trigger: "blur" },
          {
            validator: that.validatorNum,
            trigger: "blur",
          },
        ],
        textShowWidth: [
          { required: true, message: "请输入宽度", trigger: "blur" },
          {
            validator: that.validatorNum,
            trigger: "blur",
          },
        ],
        textShowHeight: [
          { required: true, message: "请输入高度", trigger: "blur" },
          {
            validator: that.validatorNum,
            trigger: "blur",
          },
        ],
        playTime: [
          { required: true, message: "请输入播放时长", trigger: "blur" },
          {
            validator: that.validatorNum,
            trigger: "blur",
          },
        ],
        textContent: [{ required: true, message: "请输入文本内容", trigger: "blur" }],
      },
    };
  },
  components: {
    titleModule,
  },
  computed: {
    labelText() {
      const { materialType } = this.formInline;
      if (materialType === 1 || materialType === 2 || materialType === 10) {
        return "图片";
      } else if (materialType === 4 || materialType === 12) {
        return "视频";
      }
      return "图片";
    },
    textFont() {
      const { textFont } = this.formInline;
      let obj = {
        1: "SimHei",
        2: "KaiTi",
        3: "SimSun",
        4: "STFangsong",
        5: "LiSu",
      };
      return obj[textFont] || "Times New Roman";
    },
    styleFontColor() {
      const { textFontColor } = this.formInline;
      let obj = {
        1: "#ff0000",
        2: "#008000",
        3: "#0000ff",
        4: "#ffff00",
        5: "#ee82ee",
        6: "#00ffff",
        7: "#ffffff",
        8: "#000000",
      };
      return obj[textFontColor];
    },
    styleTextAlign() {
      const { textAlignment } = this.formInline;
      let obj = {
        0: "left",
        1: "right",
        2: "center",
      };
      return obj[textAlignment];
    },
  },
  methods: {
    validatorNum(rule, value, callback) {
      if (!regN.test(value)) {
        callback(new Error("只能输入数字"));
      } else if (value > 2147483647) {
        callback(new Error("不能超过最大值"));
      } else {
        callback();
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 jpg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    beforeAvatarVideoUpload(file) {
      const isJPG = file.type === "video/mp4";
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isJPG) {
        this.$message.error("上传视频只能是 mp4 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 100MB!");
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, file) {
      this.mediaResourceUrl = res.value.url;
      this.uploadData.materialId = res.value.materialId;
      this.$message({
        showClose: true,
        message: "上传成功",
        type: "success",
      });
    },
    removeDomain(item) {
      var index = this.formInline.berthList.indexOf(item);
      if (index !== -1) {
        this.formInline.berthList.splice(index, 1);
        this.$message({
          showClose: true,
          message: "移除成功",
          type: "success",
        });
      }
    },
    addDomain() {
      this.formInline.berthList.push({
        textX: 0,
        textY: 0,
        textShowHeight: 0,
        textShowWidth: 0,
        textFont: 1,
        textFontSize: 16,
        berthNumUrl: "",
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.query.materialId) {
            this.handleEdit();
          } else {
            this.handleAddMaterial();
          }
        }
      });
    },
    // 添加素材
    handleAddMaterial() {
      if (!this.mediaResourceUrl && this.formInline.materialType !== 8) {
        this.$message({
          showClose: true,
          message: "图片或视频不能为空",
          type: "warning",
        });
        return false;
      }
      let query = qs.stringify(
        {
          ...this.formInline,
          mediaResourceUrl: this.mediaResourceUrl,
          materialId: this.uploadData.materialId,
        },
        {
          arrayFormat: "repeat",
        }
      );
      this.$axios
        .post(
          "/acb/2.0/ydpMaterial/add?" + query,
          {
            data: this.formInline.berthList,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          this.$router.go(-1);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleEdit() {
      if (!this.mediaResourceUrl && this.formInline.materialType !== 8) {
        this.$message({
          showClose: true,
          message: "图片或视频不能为空",
          type: "warning",
        });
        return false;
      }
      let query = qs.stringify(
        {
          ...this.formInline,
          mediaResourceUrl: this.mediaResourceUrl,
          materialId: this.uploadData.materialId,
        },
        {
          arrayFormat: "repeat",
        }
      );
      this.$axios
        .post(
          "/acb/2.0/ydpMaterial/update?" + query,
          {
            data: this.formInline.berthList,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          this.$router.go(-1);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handlePreview() {
      this.previewVisible = true;
    },
    radioClick(e) {
      if (e === this.formInline.textFontStyle) {
        setTimeout(() => {
          this.formInline.textFontStyle = 0;
        }, 100);
      }
    },
    handleMaterialType() {
      if (this.formInline.materialType === 4 || this.formInline.materialType === 12) {
        this.formInline.playTime = "";
      } else {
        this.formInline.playTime = "10";
      }
    },
  },
  created() {
    if (this.$route.query.materialId) {
      const data = this.$route.query;
      this.formInline.materialName = data.materialName;
      this.formInline.materialType = Number(data.materialType) || 1;
      this.formInline.screenWidth = data.screenWidth || 0;
      this.formInline.screenHeight = data.screenHeight || 0;
      this.formInline.mediaX = data.mediaX || 0;
      this.formInline.mediaY = data.mediaY || 0;
      this.formInline.mediaShowHeight = data.mediaShowHeight || 0;
      this.formInline.mediaShowWidth = data.mediaShowWidth || 0;
      this.formInline.textX = data.textX || 0;
      this.formInline.textY = data.textY || 0;
      this.formInline.textShowWidth = data.textShowWidth || 0;
      this.formInline.textShowHeight = data.textShowHeight || 0;
      this.formInline.textFont = Number(data.textFont) || 1;
      this.formInline.textFontSize = Number(data.textFontSize) || 16;
      this.formInline.textFontColor = Number(data.textFontColor) || 1;
      this.formInline.textFontStyle = Number(data.textFontStyle) || 0;
      this.formInline.textAlignment = Number(data.textAlignment) || 0;
      this.formInline.textPlayEffects = Number(data.textPlayEffects) || 0;
      this.formInline.textEffectsSpeed = Number(data.textEffectsSpeed) || 2;
      this.formInline.textContent = data.textContent || "";
      this.formInline.berthList = data.berthList || [
        {
          textX: 0,
          textY: 0,
          textShowHeight: 0,
          textShowWidth: 0,
          textFont: 1,
          textFontSize: 16,
          berthNumUrl: "",
        },
      ];
      this.formInline.playTime = data.playTime;
      this.mediaResourceUrl = data.mediaResourceUrl;
      this.uploadData.materialId = data.materialId;
    }
  },
};
</script>
<style lang="stylus" scoped>
.topTitle
	display flex
	justify-content space-between
	align-items center
	background-color #ffffff
.Wrapper
	background-color #ffffff
	padding 20px 0
	.avatar-uploader2
		.avatar2
			width: 160px;
			height: 160px;
			border: 1px dashed rgb(51, 51, 51);
			border-radius 6px
		.avatar-uploader-icon
			font-size: 28px;
			color: rgb(140, 147, 157);
			width: 160px;
			height: 160px;
			line-height: 160px;
			text-align: center;
			border: 1px dashed rgb(51, 51, 51);
			border-radius 6px
	.berthBox
		border 1px solid rgb(51, 51, 51)
		padding 20px
		margin 10px 0
.previewBox
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.3);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	.preview
		margin: 0 auto;
		z-index: 999;
		background: #000;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		.imgPreview
			position absolute
		.text
			position absolute
			width 100%
			overflow hidden
</style>
